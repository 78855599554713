import React, {Component} from 'react';
import {graphql} from 'gatsby';
import parse from 'html-react-parser';
import {Form} from "react-final-form";
import axios from "axios";

import BaseLayout from '../components/Layout/BaseLayout';
import Name from '../components/FormComponents/Name';
import Email from '../components/FormComponents/Email';
import Subject from '../components/FormComponents/Subject';
import Message from '../components/FormComponents/Message';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ContactPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      show: true
    });
  }

  onSubmit(values) {
    //@todo: change to endpoint grav > sendgrid
    axios
      .post("./mail.php", JSON.stringify(values))
      .then(response => {
      })
      .catch(error => {
        console.log("err: ", error.response);
      });
  }


  render() {

    const { data, location } = this.props;

    const required = value => (value ? undefined : "Verplicht");
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validateEmail = value => {
      let error;
      if (!emailRegex.test(value)) {
        error = "Vul het juiste e-mailadres in";
      }
      return error;
    };

    return (
      <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}>

          <section className="contactPage">

            <Row>
                <Col sm={12} lg={6}>
                    <h1>{data.gravql.page.title}</h1>
                </Col>
            </Row>

            <Row>
                <Col sm={12} lg={6}>
                    {
                        parse(data.gravql.page.content)
                    }
                </Col>
                <Col sm={12} lg={6}>
                    <div className="formWrapper">
                        <Form
                            onSubmit={this.onSubmit}
                            render={({ handleSubmit, reset, submitting, pristine, values }) => (
                                <form>
                                    {/* FIRSTNAME */}
                                    <Name required={required} />

                                    {/* EMAIL */}
                                    <Email validate={validateEmail} />

                                    {/* SUBJECT */}
                                    <Subject required={required} />

                                    {/* MESSAGE */}
                                    <Message required={required} />
                                    <span
                                        className="submit"
                                        onClick={event => {
                                            event.preventDefault();
                                            handleSubmit();
                                        }}
                                        disabled={submitting}
                                    >Verzenden</span>

                                </form>
                            )}
                        />

                    </div>
                </Col>
            </Row>

        </section>
      </BaseLayout>
    )
  }
}

export default ContactPage;

export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route
                breadcrumbs
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                    header {
                        title
                        body_classes
                    }
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route    
                content   
                 children {
                    title
                    route
                }         
            }
            imagesCropped(crop:true, width: "200", height:"200"){
                path
                filename
                url
                width
                height
            }
            metadata {
                name
                content
            }   
        }
    }
  }
`
